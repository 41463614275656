import React from "react";
import DropoffButton from "./DropoffButton";

export type DropoffLinkProps = {
  dropoff: any;
};

const DropoffLink: React.FC<DropoffLinkProps> = ({ dropoff }) => {
  const coordinates = dropoff.location.coordinates;
  let mapURL;

  mapURL = `https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`;

  return (
    <a id="dropoff_link" target="_blank" rel="noopener noreferrer" href={mapURL} className="pr-2">
      <p className="text-[12px] underline underline-offset-2 ">
        {dropoff.name}
      </p>
    </a>
  );
};

export default DropoffLink;
