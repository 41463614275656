import React, { useContext } from "react";
import { LocationContext } from "../../context/LocationProvider";
import geolocation from "../geolocation";

const useLocation = () => {
  const {
    setIsLoading,
    setHasGeolocationEnabled,
    hasGeolocationEnabled,
    setLocation,
  } = useContext(LocationContext);

  const handlePermissions = async () => {
    const result = await navigator.permissions.query({ name: "geolocation" });

    switch (result.state) {
      case "granted":
        return true;
      case "prompt":
        return true;
      case "denied":
        setHasGeolocationEnabled && setHasGeolocationEnabled(false);
        return false;
      default:
        return false;
    }
  };

  const requestLocation = () => {
    setIsLoading && setIsLoading(true);
    if (!navigator.permissions || !navigator.geolocation) {
      setHasGeolocationEnabled && setHasGeolocationEnabled(false);
      setIsLoading && setIsLoading(false);
      return;
    }
    (async () => {
      const canFetchLocation = await handlePermissions();

      if (hasGeolocationEnabled && canFetchLocation) {
        try {
          const position = await geolocation.getCurrentPosition();
          setLocation && setLocation(position);
        } catch (err) {
          setHasGeolocationEnabled && setHasGeolocationEnabled(false);
        }
      }
      setIsLoading && setIsLoading(false);
    })();
  };

  return { requestLocation };
};

export default useLocation;
