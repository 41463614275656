import React from "react";
import visibilityConfig from "../util/visibilityConfig";

const Frame: React.FC = ({ children }) => {
  const bgColor = visibilityConfig.bgColor ?? "fff";
  const themeColor = visibilityConfig.themeColor ?? "4c9e66";

  return (
    <div
      className="pt-1 px-3.5 flex font-raleway flex-col items-center w-full justify-between"
      style={{
        backgroundColor: `#${themeColor}`,
      }}
    >
      <div className="text-xl tracking-wide font-black p-1 rounded-t-lg w-full text-white text-center">
        CAN I RECYCLE THIS?
      </div>
      <div
        className="overflow-auto relative w-full h-full"
        style={{
          backgroundColor: `#${bgColor}`,
        }}
      >
        {children}
      </div>
      <div className="rounded-b-lg w-full text-center py-2 text-white">
        <a href="https://www.cirt.tech" target="_blank" rel="noopener noreferrer" id="powered_by_cirt">
          powered by <span className="font-bold">CIRT</span>
        </a>
      </div>
    </div>
  );
};

export default Frame;
