import React, { useContext } from "react";
import { LocationContext } from "../../context/LocationProvider";
import LocationButton from "../LocationButton";
import SearchZipcode from "../sidebar/SearchZipcode";

const LocationContainer = () => {
  const { hasGeolocationEnabled } = useContext(LocationContext);

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      {hasGeolocationEnabled ? (
        <LocationButton />
      ) : (
        <div className="text-center">
          <div className="font-raleway font-bold">Oops!</div>
          <div className="text-sm text-cirt-grey">
            Check your browser to enable location services.
          </div>
        </div>
      )}
      <div className="my-5 text-cirt-grey font-bold">— OR —</div>
      <SearchZipcode />
    </div>
  );
};

export default LocationContainer;
