function getConfig() {
  if (!window?.location.search) return {};
  const url = window.location.search.split("?")[1];
  var result: { [k: string]: string } = {};
  url.split("&").forEach((key) => {
    var item = key.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

const config = getConfig();

function booleanCheck(configParam: any) {
  let out = false;
  (!config[configParam] || config[configParam] === "true") ? (out = true) : (out = false)

  return out;
}

const toggleConfig = {
  hideBottomBar: !!config["hideBottomBar"],
  hideImage: !!config["hideImage"],
  themeColor: config["themeColor"],
  bgColor: config["bgColor"],
  hideMap: !!config["hideMap"],
  isPackageInfoVisible: booleanCheck("isPackageInfoVisible"),
  isProductInfoVisible: booleanCheck("isProductInfoVisible"),
};

export default toggleConfig;
