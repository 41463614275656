import React from "react";
import DropoffLink from "./DropoffLink";

const DropoffsContainer: React.FC<{ dropoffs: any[] }> = ({ dropoffs }) => {
  return (
    <div className="mt-1 flex flex-col items-center">
      <div className="flex w-full flex-wrap">
        {dropoffs.map((drop: any, i: number) => {
          return <DropoffLink key={`dropoff-${i}`} dropoff={drop} />;
        })}
      </div>
    </div>
  );
};

export default DropoffsContainer;
