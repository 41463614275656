import React from "react";
import LoadingAnimation from "../icons/LoadingAnimation";

const LoadingContainer = () => {
  return (
    <div className="flex flex-col h-full justify-center items-center w-full">
      <div className="font-raleway text-lg">Fetching Results...</div>
      <LoadingAnimation width={325} height={200} />
    </div>
  );
};

export default LoadingContainer;
