import React, { useRef, useState } from 'react'

interface AccordionProps {
  title: React.ReactNode
  content: React.ReactNode
  dropoff?: boolean
}

export const Accordion: React.FC<AccordionProps> = ({ title, content, dropoff }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('0px')
  const [rotate, setRotate] = useState('transform duration-700 ease')

  const contentSpace = useRef(null)

  function toggleAccordion() {
    setActive((prevState) => !prevState)
    // @ts-ignore
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight + 10}px`)
    setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180')
  }

  return (
    <div className=" flex flex-col w-full">
      <button
        className="mt-1 cursor-pointer flex flex-row items-center justify-between"
        onClick={toggleAccordion}
        id={dropoff ? 'dropoff_button' : 'instruction_button'}
      >
        {title}
        <svg width="15" height="15" className={`${rotate} shrink-0 opacity-50 mt-1`} viewBox="0 0 16 16">
            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto transition-max-height duration-700 ease-in-out mb-3"
      >
        <div className="my-2 text-s">{content}</div>

      </div>

    </div>
  )
}

export default Accordion;
