import React, { useContext } from "react";
import { LocationContext } from "../../context/LocationProvider";

const LocationError = () => {
  return (
    <div className="flex flex-col  h-full">
      <h1 className="text-xl text-center mb-1">
        <strong>We see you.</strong>
      </h1>
      <p className="mx-2 mt-0 text-center text-cirt-black max-w-lg">
        We're still working on mapping your area. Check
        back soon, we're adding new recycling areas every week.
      </p>
      <br />
      <p className="mx-2 mb-2 mt-0 text-center text-cirt-black">
        If you have a specific recycling question, ask us at{" "}
        <a className="underline" href="mailto: hi@cirt.tech">
          hi@cirt.tech
        </a>
      </p>
    </div>
  );
};

const ErrorContainer: React.FC = () => {
  const { error, reset } = useContext(LocationContext);
  const noService = error === "NO_SERVICE";

  return (
    <div className="flex flex-col items-center justify-center">
      {noService && <LocationError />}
      {error && !noService && (
        <div>
          <h1 className="text-xl text-center">
            {error ? error : "Whoops! Something went wrong."}
          </h1>
          <span className="mx-2 mt-0 text-center sm:text-left text-white">
            Check your browser for information on how to enable location
            services.
          </span>
        </div>
      )}

      <button
        onClick={() => {
          reset && reset();
        }}
        className="bg-cirt-green w-3/5 text-center p-2 rounded-3xl text-white cursor-pointer select-none"
      >
        Retry
      </button>
    </div>
  );
};

export default ErrorContainer;
