import { useCallback, useEffect } from "react";
import { cirtApi } from "../constants/api";

export type Coordinates = {
  latitude: number;
  longitude: number;
};

const useRecyclingAction = () => {
  const fetchData = async (coords?: Coordinates) => {
    if (!coords) return;
    const barcode = window.location.pathname.split("/")[1];
    const postData = {
      barcode,
      location: coords,
      source: "legacy-results",
    };

    const data = await (
      await cirtApi.post("/recycling/barcode", postData)
    ).data;
    const result = data.recyclingAction;
    const clients = data.clients;
    return { ...result, clients };
  };

  const getData = useCallback(async (coords?: Coordinates) => {
    const result = await fetchData(coords);
    return result;
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    getData,
  };
};
export default useRecyclingAction;
