import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import LocationProvider from "./context/LocationProvider";
import RedirectContainer from "./components/containers/RedirectContainer";
import TagManager from 'react-gtm-module';

// Google Tag Manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <RedirectContainer pathname={window.location.pathname}>
      <LocationProvider>
        <App />
      </LocationProvider>
    </RedirectContainer>
  </React.StrictMode>,
  document.getElementById("root")
);
