import React, { useEffect, useState } from "react";
import useProductRedirect from "../../util/hooks/useProductRedirect";

type RedirectContainerProps = {
  pathname: string;
};

const RedirectContainer: React.FC<RedirectContainerProps> = ({
  pathname,
  children,
}) => {
  const { getRedirectUrl } = useProductRedirect();
  const [redirectUrl, setRedirectUrl] = useState<string>();

  const barcode = window.location.pathname.split("/")[1];

  function isInIframe() {
    return window.self !== window.top;
  }

  useEffect(() => {
    (async () => {
      const redirect_url = await getRedirectUrl();

      setRedirectUrl(redirect_url);
    })();
  }, []);

  useEffect(() => {
    if (pathname === "/") {
      window.location.replace("https://cirt.tech");
      return;
    }

    // when working locally, please comment out this entire if
    if (!isInIframe()) {
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      } else {
        window.location.replace(`https://check.cirt.tech/widget/${barcode}`);
      }
    }
  }, [redirectUrl]);

  return <div>{children}</div>;
};

export default RedirectContainer;
