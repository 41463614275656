import axios from "axios";
import { cirtApi } from "./constants/api";

const geolocation = {
  getCurrentPosition: () => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (positionError) => {
          reject(positionError);
        }
      );
    });
  },
  
  getCoordinatesFromZipCode: async (zipCode: string) => {
    try {
      const postData = {
        zipCode,
      };

      const result = await (await cirtApi.post("/location", postData)).data;

      if (result.data?.status === "ZERO_RESULTS") {
        throw new Error("Failed to geolocate zipcode.");
      }
      const coordinates = result;
      return {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      };
    } catch (error) {
      throw error;
    }
  },
};

export default geolocation;
