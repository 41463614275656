import React, { useEffect, useState } from "react";
import useIsMounted from "../../util/hooks/useIsMounted";
import LottieAnimation, { AnimationProps } from "./LottieAnimation";

const LoadingAnimation: React.FC<AnimationProps> = ({ width, height }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const isMounted = useIsMounted();

  const currWidth = width ?? 500;
  const currHeight = height ?? 500;

  useEffect(() => {
    (async () => {
      if (!isMounted) return;
      setIsLoading(true);
      const data = await import("../../animations/cycling.json");
      setData(data);
      setIsLoading(false);
    })();
  }, [isMounted]);

  if (isLoading) return null;
  return (
    <LottieAnimation
      animationData={data}
      width={currWidth}
      height={currHeight}
    />
  );
};

export default LoadingAnimation;
