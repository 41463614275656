import React, { createContext, useEffect, useState } from "react";
import { ResultType } from "../types/types";
import { SERVICE_STATUS } from "../util/constants/recycleActions";
import geolocation from "../util/geolocation";
import useRecyclingAction from "../util/hooks/useRecyclingAction";

export type LocationContextState = {
  result?: ResultType | undefined;
  location?: GeolocationPosition;
  setLocation?: (l: GeolocationPosition) => void;
  zipCode?: string;
  setZipCode?: (s: string) => void;
  error?: any;
  setError?: (s: string) => void;
  isLoading?: boolean;
  setIsLoading?: (b: boolean) => void;
  hasGeolocationEnabled?: boolean;
  setHasGeolocationEnabled?: (b: boolean) => void;
  cancelSearch?: () => void;
  reset?: () => void;
};

const handleError = (error: any) => {
  if (error.service === SERVICE_STATUS.NO_SERVICE) {
    return "NO_SERVICE";
  } else {
    return error.status;
  }
};

export const LocationContext = createContext<LocationContextState>({});

const LocationProvider: React.FC = ({ children }) => {
  const { getData } = useRecyclingAction();

  const [zipCode, setZipCode] = useState<string>("");
  const [result, setResult] = useState<ResultType | undefined>(undefined);
  const [location, setLocation] = useState<GeolocationPosition>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [hasGeolocationEnabled, setHasGeolocationEnabled] =
    useState<boolean>(true);

  const cancelSearch = () => {
    setHasGeolocationEnabled(false);
    setIsLoading(false);
  };

  const reset = () => {
    setZipCode("");
    setLocation(undefined);
    setIsLoading(false);
    setError(undefined);
    setResult(undefined);
  };

  useEffect(() => {
    (async () => {
      let coordinates;
      setIsLoading(true);

      try {
        if (zipCode) {
          coordinates = (await geolocation.getCoordinatesFromZipCode(
            zipCode
          )) as unknown as GeolocationCoordinates;
        } else if (location) {
          coordinates = {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
          };
        }

        if (coordinates) {
          const recyclingResult = await getData(coordinates);
          if (recyclingResult) {
            setResult(recyclingResult);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } catch (error: any) {
        if (error.response?.data) {
          setError(handleError(error.response.data));
        } else {
          error.name = "";
          setError(error.toString());
        }
        setIsLoading(false);
      }
    })();
  }, [zipCode, location, getData]);

  const state: LocationContextState = {
    result,
    zipCode,
    setZipCode,
    location,
    setLocation,
    error,
    setError,
    isLoading,
    setIsLoading,
    hasGeolocationEnabled,
    setHasGeolocationEnabled,
    cancelSearch,
    reset,
  };

  return (
    <LocationContext.Provider value={state}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
