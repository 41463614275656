import React, { useContext, useState } from "react";
import { LocationContext } from "../../context/LocationProvider";
import visibilityConfig from "../../util/visibilityConfig";
import TagManager from "react-gtm-module";

const SearchZipcode = () => {
  const buttonColor = visibilityConfig.themeColor ?? "4c9e66";

  const { setZipCode } = useContext(LocationContext);

  const [zip, setZip] = useState<string>("");

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    if (!zip) {
      return;
    }
    TagManager.dataLayer({ dataLayer: { event: "zipcode", zipcode: zip } });
    setZipCode && setZipCode(zip);
    setZip && setZip("");
  };

  return (
    <div className="flex justify-center sm:pt-0 w-full">
      <div className="mx-2 sm:mx-0 flex flex-col items-center">
        <form
          id="zipcode_form"
          onSubmit={handleSubmit}
          className="w-72 flex flex-row"
        >
          <input
            id="zipcode_input"
            onChange={(e) => {
              setZip(e.target.value);
            }}
            type="string"
            value={zip}
            className="w-full p-2 px-10 text-center focus:outline-cirt-green border rounded-l-xl"
            style={{
              borderColor: `#${buttonColor}`,
            }}
            placeholder="Enter Zip Code"
          />
          <button
            className="w-20 text-white rounded-r-xl"
            style={{
              backgroundColor: `#${buttonColor}`,
            }}
          >
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchZipcode;
