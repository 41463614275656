import React from "react";

const ProductImage: React.FC<{ src: string }> = ({ src }) => {
  return (
    <div className="">
      <img className="object-scale-down w-32 h-32" alt="product" src={src} />
    </div>
  );
};

export default ProductImage;
