import { useContext } from "react";
import LoadingContainer from "./components/containers/LoadingContainer";
import ResultContainer from "./components/containers/ResultContainer";
import { LocationContext } from "./context/LocationProvider";
import Frame from "./components/Frame";
import LocationContainer from "./components/containers/LocationContainer";
import ErrorContainer from "./components/containers/ErrorContainer";

function App() {
  const { isLoading, result, error, hasGeolocationEnabled } =
    useContext(LocationContext);

  const noService = error === "NO_SERVICE";

  return (
    <div className="h-screen min-h-[450px] w-[368px] flex m-auto hidden-scrollbar">
      <Frame>
        {noService && !isLoading && <ErrorContainer />}
        {result && !isLoading && !noService && <ResultContainer />}
        {!result && !isLoading && !noService && <LocationContainer />}
        {isLoading && <LoadingContainer />}
      </Frame>
    </div>
  );
}

export default App;
