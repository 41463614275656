import React from "react";
import useLocation from "../util/hooks/useLocation";
import visibilityConfig from "../util/visibilityConfig";


const LocationButton = () => {
  const buttonColor = ("#" + visibilityConfig.themeColor) ?? "bg-cirt-green";
  const { requestLocation } = useLocation();

  return (
    <div
      id="location_button"
      onClick={requestLocation}
      className="bg-cirt-green w-72 text-center p-2 rounded-3xl text-white cursor-pointer select-none"
      style={{ 
        maxWidth: "316px",
        backgroundColor: `${buttonColor}`
      }}
    >
      Use my location
    </div>
  );
};

export default LocationButton;
