import React from "react";
import visibilityConfig from "../../util/visibilityConfig";

const DashIcon = () => {
  const themeColor = visibilityConfig.themeColor ?? "4c9e66";

  return (
    <div>
      <svg
        className="w-5"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM37.5 27.5H12.5V22.5H37.5V27.5Z"
          fill={`#${themeColor}`}
          fillOpacity="0.5"
        />
        <path
          d="M37.5 28H38V27.5V22.5V22H37.5H12.5H12V22.5V27.5V28H12.5H37.5ZM0.5 25C0.5 11.4761 11.4761 0.5 25 0.5C38.5239 0.5 49.5 11.4761 49.5 25C49.5 38.5239 38.5239 49.5 25 49.5C11.4761 49.5 0.5 38.5239 0.5 25Z"
          stroke="black"
          strokeOpacity="0.5"
        />
      </svg>
    </div>
  );
};

export default DashIcon;
