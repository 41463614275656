import { useCallback, useEffect } from "react";
import { cirtApi } from "../constants/api";

const useProductRedirect = () => {
  const fetchRedirectUrl = async () => {
    const barcode = window.location.pathname.split("/")[1];

    const data = await (
      await cirtApi.get(`/products/${barcode}/redirect`)
    ).data;

    console.log(data);

    const result: string = data.url;

    return result;
  };

  const getRedirectUrl = useCallback(async () => {
    const result = await fetchRedirectUrl();
    return result;
  }, []);

  return {
    getRedirectUrl,
  };
};

export default useProductRedirect;
