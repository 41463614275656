export const RECYCLE_ACTIONS = Object.freeze({
  RECYCLE_YES: "RECYCLE_YES",
  RECYCLE_NO: "RECYCLE_NO",
  SPECIAL_DROP: "SPECIAL_DROP",
  UNKNOWN: "UNKNOWN",
});

export const SERVICE_STATUS = Object.freeze({
  IN_SERVICE: "IN_SERVICE",
  NO_SERVICE: "NO_SERVICE",
});
