import React from "react";
import visibilityConfig from "../../util/visibilityConfig";

const CheckmarkIcon = () => {
  const themeColor = visibilityConfig.themeColor ?? "4c9e66";
  
  return (
    <div>
      <svg
        className="w-5"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM20 37.5L7.5 25L11.025 21.475L20 30.425L38.975 11.45L42.5 15L20 37.5Z"
          fill={`#${themeColor}`}
        />
        <path
          d="M19.6464 37.8536L20 38.2071L20.3536 37.8536L42.8536 15.3536L43.2059 15.0012L42.8548 14.6477L39.3298 11.0977L38.9762 10.7416L38.6214 11.0964L19.9995 29.7184L11.3781 21.121L11.0245 20.7684L10.6714 21.1214L7.14645 24.6464L6.79289 25L7.14645 25.3536L19.6464 37.8536ZM0.5 25C0.5 11.4761 11.4761 0.5 25 0.5C38.5239 0.5 49.5 11.4761 49.5 25C49.5 38.5239 38.5239 49.5 25 49.5C11.4761 49.5 0.5 38.5239 0.5 25Z"
          stroke="black"
          strokeOpacity="0.5"
        />
      </svg>
    </div>
  );
};

export default CheckmarkIcon;
