import React from "react";
import { RECYCLE_ACTIONS } from "../util/constants/recycleActions";
import CheckmarkIcon from "./icons/CheckmarkIcon";
import CrossmarkIcon from "./icons/CrossmarkIcon";
import DashIcon from "./icons/DashIcon";

const RecoverabilityLabel: React.FC<{ name: string; action: string }> = ({
  name,
  action,
}) => {
  const configMap = {
    [RECYCLE_ACTIONS.RECYCLE_YES]: {
      color: "bg-cirt-green-alt",
      text: "Curbside",
      href: "https://www.cirt.tech/curbside-recycling",
    },
    [RECYCLE_ACTIONS.RECYCLE_NO]: {
      color: "bg-cirt-red",
      text: "Garbage",
      href: "http://www.cirt.tech/landfill",
    },
    [RECYCLE_ACTIONS.SPECIAL_DROP]: {
      color: "rgba(var(bg-cirt-green), 0.5)",
      text: "Dropoff",
      href: "https://www.cirt.tech/in-store-drop-off",
    },
    [RECYCLE_ACTIONS.UNKNOWN]: {
      color: "rgba(var(bg-cirt-green), 0.5)",
      text: "Unknown",
    },
  };

  if (!configMap[action]) {
    return null;
  }

  const currConfig = configMap[action];

  return (
    <div className="flex flex-row items-start w-full justify-between py-2.5 px-4">
      <div className="flex items-start">
        <div className="my-1">
          {action === RECYCLE_ACTIONS.RECYCLE_NO && <CrossmarkIcon />}
          {action === RECYCLE_ACTIONS.SPECIAL_DROP && <DashIcon />}
          {action === RECYCLE_ACTIONS.RECYCLE_YES && <CheckmarkIcon />}
          {action === RECYCLE_ACTIONS.UNKNOWN && <CrossmarkIcon />}
        </div>
        <p className={`inline-block pl-4 pr-4 font-semibold text-l`}>{name}:</p>
      </div>
      <div className="flex flex-col justify-end">
        <a
          href={currConfig.href}
          target="_blank"
          rel="noopener noreferrer"
          id="action_ahref"
        >
          <span className="">{currConfig.text}</span>
        </a>
      </div>
    </div>
  );
};

export default RecoverabilityLabel;
