import React, { useContext, useEffect, useState } from "react";
import { LocationContext } from "../../context/LocationProvider";
import visibilityConfig from "../../util/visibilityConfig";
import DropoffsContainer from "../dropoff/DropoffsContainer";
import DropoffMap from "../dropoff/map/DropoffMap";
import ProductImage from "../ProductImage";
import RecoverabilityLabel from "../RecoverabilityLabel";
import ErrorContainer from "./ErrorContainer";
import Accordion from "../Accordion";
import { RECYCLE_ACTIONS } from "../../util/constants/recycleActions";
import TagManager from "react-gtm-module";
import { ProductTag } from "../../types/types";

const ResultContainer: React.FC = () => {
  const { result, error, isLoading } = useContext(LocationContext);

  const imageSrc = result?.product?.product?.product_image;
  const productDropoffs: any[] | undefined = result?.product.dropoffs;

  const noService = error === "NO_SERVICE";

  const themeColor = visibilityConfig.themeColor ?? "4c9e66";

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: { event: "areaName", areaName: result?.area.name },
    });
    TagManager.dataLayer({
      dataLayer: { event: "areaId", areaId: result?.area._id },
    });
  }, []);

  return (
    <div className="flex flex-1 items-start pt-3 justify-center h-full">
      {!isLoading && result && !noService ? (
        <div
          id="loaded_result"
          className={
            !isLoading && result && !noService
              ? "flex flex-col justify-center w-full pt-2 "
              : "hidden"
          }
        >
          <div className="mx-3 flex flex-col">
            {imageSrc && !visibilityConfig.hideImage && (
              <div className="flex flex-col justify-center items-center pt-2">
                <ProductImage src={imageSrc} />
              </div>
            )}
            <div className="flex font-bold text-center w-full justify-center mt-3">
              {result.product.product.name}
            </div>
            {result?.product?.product.tags && (
              <div className="flex flex-wrap mb-2 justify-center">
                {result?.product?.product.tags.map((tag: ProductTag) => (
                  <span
                    className={`rounded-3xl m-1 p-1 px-3 text-xs bg-slate-300`}
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            )}
            <div className="px-5 mb-4">
              <p
                className="text-center py-2 rounded-3xl text-white"
                style={{
                  backgroundColor: `#${themeColor}`,
                }}
              >
                In {result?.area.name}:
              </p>
            </div>
            {/* Packaging Labels */}
            <div
              id="recoverability-labels"
              className={
                visibilityConfig.isPackageInfoVisible ? "text-s mx-0" : "hidden"
              }
            >
              {/* Primary */}
              {visibilityConfig.isPackageInfoVisible &&
                result?.packaging.primary.map((pack, i) => {
                  const packagingDropoffs: any[] | undefined = pack.dropoffs;
                  // IF RECYCLING DOES NOT HAVE DROP-OFF OPTIONS
                  if (
                    pack.action === RECYCLE_ACTIONS.RECYCLE_YES ||
                    pack.action === RECYCLE_ACTIONS.RECYCLE_NO
                  ) {
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.packaging.name}
                          action={pack.action}
                        />
                      </div>
                    );
                  } else {
                    // ELSE, SHOW DROP OFF OPTIONS
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.packaging.name}
                          action={pack.action}
                        />
                        <div className="ml-8 -mt-4 px-5">
                          <Accordion
                            title={
                              <p className="text-sm">
                                Drop off locations near you
                              </p>
                            }
                            content={
                              packagingDropoffs &&
                              packagingDropoffs?.length > 0 && (
                                <DropoffsContainer
                                  dropoffs={packagingDropoffs}
                                />
                              )
                            }
                            dropoff={true}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              {/* Secondary */}
              {visibilityConfig.isPackageInfoVisible &&
                result?.packaging.secondary.length > 0 &&
                result?.packaging.secondary.map((pack, i) => {
                  const packagingDropoffs: any[] | undefined = pack.dropoffs;
                  // IF RECYCLING DOES NOT HAVE DROP-OFF OPTIONS
                  if (
                    pack.action === RECYCLE_ACTIONS.RECYCLE_YES ||
                    pack.action === RECYCLE_ACTIONS.RECYCLE_NO
                  ) {
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.packaging.name}
                          action={pack.action}
                        />
                      </div>
                    );
                  } else {
                    // ELSE, SHOW DROP OFF OPTIONS
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.packaging.name}
                          action={pack.action}
                        />
                        <div className="ml-8 -mt-4 px-5">
                          <Accordion
                            title={
                              <p className="text-sm">
                                Drop off locations near you
                              </p>
                            }
                            content={
                              packagingDropoffs &&
                              packagingDropoffs?.length > 0 && (
                                <DropoffsContainer
                                  dropoffs={packagingDropoffs}
                                />
                              )
                            }
                            dropoff={true}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            {/* Product Material Labels  */}
            <div
              id="recoverability-labels"
              className={
                visibilityConfig.isProductInfoVisible ? "text-s mx-0" : "hidden"
              }
            >
              {visibilityConfig.isProductInfoVisible &&
                result?.product?.product?.product_materials.map((pack, i) => {
                  // IF RECYCLING DOES NOT HAVE DROP-OFF OPTIONS
                  if (
                    result?.product?.action === RECYCLE_ACTIONS.RECYCLE_YES ||
                    result?.product?.action === RECYCLE_ACTIONS.RECYCLE_NO
                  ) {
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.name}
                          action={result?.product?.action}
                        />
                      </div>
                    );
                  } else {
                    // ELSE, SHOW DROP OFF OPTIONS
                    return (
                      <div className="transition fade-in-out hover:bg-gray-200">
                        <RecoverabilityLabel
                          key={i}
                          name={pack.name}
                          action={result?.product?.action}
                        />
                        <div className="ml-8 -mt-4 px-5">
                          <Accordion
                            title={
                              <p className="text-sm">
                                Drop off locations near you
                              </p>
                            }
                            content={
                              productDropoffs &&
                              productDropoffs?.length > 0 && (
                                <DropoffsContainer dropoffs={productDropoffs} />
                              )
                            }
                            dropoff={true}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            {/* Special Instructions Dropdown */}
            {visibilityConfig.isPackageInfoVisible &&
              result?.packaging?.primary.some(
                (pack) => pack.packaging?.notes
              ) && (
                <div className="mt-3 px-5 pb-5">
                  <Accordion
                    title={
                      <p className="text-base font-bold">
                        Special Instructions
                      </p>
                    }
                    content={result?.packaging?.primary.map((pack, i) => {
                      if (!pack?.packaging?.notes) return null;
                      return (
                        <p className="text-sm pb-2">{pack?.packaging.notes}</p>
                      );
                    })}
                  />
                </div>
              )}
          </div>
        </div>
      ) : (
        <ErrorContainer />
      )}
    </div>
  );
};

export default ResultContainer;
