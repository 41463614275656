import React from "react";

const CrossmarkIcon = () => {
  return (
    <div>
      <svg
        className="w-5"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 0C11.175 0 0 11.175 0 25C0 38.825 11.175 50 25 50C38.825 50 50 38.825 50 25C50 11.175 38.825 0 25 0ZM37.5 33.975L33.975 37.5L25 28.525L16.025 37.5L12.5 33.975L21.475 25L12.5 16.025L16.025 12.5L25 21.475L33.975 12.5L37.5 16.025L28.525 25L37.5 33.975Z"
          fill="white"
        />
        <path
          d="M37.8536 34.3286L38.2071 33.975L37.8536 33.6214L29.2321 25L37.8536 16.3786L38.2071 16.025L37.8536 15.6714L34.3286 12.1464L33.975 11.7929L33.6214 12.1464L25 20.7679L16.3786 12.1464L16.025 11.7929L15.6714 12.1464L12.1464 15.6714L11.7929 16.025L12.1464 16.3786L20.7679 25L12.1464 33.6214L11.7929 33.975L12.1464 34.3286L15.6714 37.8536L16.025 38.2071L16.3786 37.8536L25 29.2321L33.6214 37.8536L33.975 38.2071L34.3286 37.8536L37.8536 34.3286ZM0.5 25C0.5 11.4511 11.4511 0.5 25 0.5C38.5489 0.5 49.5 11.4511 49.5 25C49.5 38.5489 38.5489 49.5 25 49.5C11.4511 49.5 0.5 38.5489 0.5 25Z"
          stroke="black"
          strokeOpacity="0.5"
        />
      </svg>
    </div>
  );
};

export default CrossmarkIcon;
